import { DEFAULT_APPROVAL_FILTER, PORTALUK, EN } from "./constants";

const config = {
  clientCode: PORTALUK,
  clientId: 10041,
  lang: [EN],
  defaultLang: EN,
  uiSettings: {
    brandSelection: true,
    languageSwitch: false,
    toolsMenu: false,
    downloadsMenu: false,
    showPrices: true,
    showCustomerApproval: true,
    approvalFilter: DEFAULT_APPROVAL_FILTER,
    showAvailableCycles: true,
    showMigrationLink: false,
    sidebarFAQ: null,
    showFooterText: true,
    maintenanceMessage: null,
    showCalendar: true,
    showSuppliers: true,
    showRequestAccountButton: true,
    showSidebarAL: true,
    showLogos: true,
    showSignatures: true,
    weekendParticipationDate: true,
    participationDateDelay: 21,
    showParticipationDate: false,
  },
  downloadsPageReport: {
    reportId: null,
    viewId: null,
  },
};

export default config;
